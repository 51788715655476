import React from "react";
import dashboard from "../image/sidebar-icon/Dashboard.svg";
import logo from "../image/Group 1000004870.png";
import sell_token from "../image/sidebar-icon/homepage.svg";
import buy_token from "../image/sidebar-icon/buy-token.svg";
import withdraw from "../image/sidebar-icon/binance-logo.svg";
import whitepaper from "../image/sidebar-icon/whitepaper.svg";
import refer from "../image/sidebar-icon/refer.svg";
import audit from "../image/sidebar-icon/audit.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RiMenu4Line } from "react-icons/ri";

const Saidbar = () => {
  let navigate = useNavigate();

  const [activeLink, setActiveLink] = useState("/");
  const location = useLocation();

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <div>
      <div className="sidebar p-md-3" >
        <div className="navbarnone">
          <nav className="navbar navbar-expand-md ">
            <div className="logo">
              <div className="togglebtn">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                >
                  <span className="navbar-toggler-icon">
                    <RiMenu4Line style={{
                      fontSize: 29,
                      color: "#784eba",
                      fontWeight: 900
                    }} />
                  </span>
                </button>
              </div>
              <div className="logos_menu">
                <h4 className="desktop-logo " href="#" >
                  {" "}
                  <img src={logo} alt="Logo" className="" />{" "}
                </h4>
                <h4 className="mobile-logo" href="#">
                  {" "}
                  <img src={logo} alt="Logomobile" />{" "}
                </h4>
              </div>
            </div>
            <div className="menubar w-100 d-flex justify-content-center align-content-center">
              <div className="menucontant w-100 ">
                <div
                  className="collapse navbar-collapse"
                  id="collapsibleNavbar"
                >
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                    onClick={() => handleLinkClick("/")}
                  >
                    <img width={"20"} src={dashboard} alt="dasicon" />
                    Dashboard
                  </Link>
                  
          
                  <a
                                       target='_blank'
                                        href="https://bscscan.com/address/0x7d185d79b7d28be5a9a9f8f1dce6aab7e5462c83"
                                       
                                    >
                                        <img width={"20"} src={withdraw} alt="rewicon" /> CWN Contract
                                    </a>

                                    <a
                                    target='_blank'
                                      href="https://bscscan.com/address/0xBE2De0D93DaDF21839aCc6d67d9d33d12cb5bb7C">
                                      <img width={"20"} src={withdraw} alt="rewicon" /> Presale contract
                                    </a>
                                    <hr className="hr-tag" />
                                    <a
                                        href="/Copywing.pdf"
                                        download="Copywing.pdf"
                                        
                                    >
                                        <img width={"20"} src={audit} alt="rewicon" /> Copywing PDF
                                    </a>

                  <a
                                      href="https://copywing.io/">
                                      <img width={"20"} src={sell_token} alt="rewicon" /> Homepage
                                    </a>
                  {/* <a href="/" download="CUNWhitepaper.pdf">
                    <img width={"20"} src={whitepaper} alt="rewicon" /> Setting
                  </a> */}

                  {/* 
                  <Link
                    to="/level-rewards"
                    className={
                      location.pathname === "/level-rewards" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/level-rewards")}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" /> Level
                    Rewards
                  </Link>

                  <Link
                    to="/LedarshipRewardList"
                    className={
                      location.pathname === "/LedarshipRewardList" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/LedarshipRewardList")}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" />
                    Leadership Reward
                  </Link>

                  <Link
                    to="/DairectIncome"
                    className={
                      location.pathname === "/DairectIncome" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/DairectIncome")}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" />
                    Direct Income
                  </Link>

                  <Link
                    to="/daily-rewards"
                    className={
                      location.pathname === "/daily-rewards" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/daily-rewards")}
                  >
                    <img width={"20"} src={sell_token} alt="rewicon" /> Daily
                    Rewards
                  </Link>

                  <Link
                    to="/team-bonus"
                    className={
                      location.pathname === "/team-bonus" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/team-bonus")}
                  >
                    <img width={"20"} src={refer} alt="rewicon" /> Team Bonus
                  </Link>

                  <Link
                    to="/withdrawal-list"
                    className={
                      location.pathname === "/withdrawal-list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/withdrawal-list")}
                  >
                    <img width={"20"} src={withdraw} alt="rewicon" /> withdrawal
                    List
                  </Link> */}
                  
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Saidbar;
