import React, { useState, useEffect } from "react";
import {
  useSDK,
  useContract,
  useAddress,
  useContractRead,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import moment from "moment";
import "./page.css";
import { BASE_URL } from "../constants";

const DailyRewards = () => {
  const [stakedData, setStakedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageLimit = 5; // Number of pages to display at a time
  const address = useAddress();
  const sdk = useSDK();

  const { contract } = useContract(
    "0x7d2291988b4f8d3fed0c6aefa131c39586fcb88a"
  );
  const { data: userCounts, isLoading: isUserCountsLoading } = useContractRead(
    contract,
    "UserCounts",
    [address]
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       const contract1 = await sdk.getContract(
  //         "0x7d2291988b4f8d3fed0c6aefa131c39586fcb88a"
  //       );
  //       const len = Number(userCounts.buyCount.toString());
  //       const details = [];

  //       for (let i = 0; i < len; i++) {
  //         const data = await contract1.call("userBuys", [address, i]);
  //         const parent = data.parent;
  //         const amount = parseFloat(
  //           ethers.utils.formatUnits(data.amount.toString())
  //         ).toFixed(2);
  //         const currRate = parseFloat(
  //           ethers.utils.formatUnits(data.currentRate.toString())
  //         ).toFixed(7);
  //         const date = moment
  //           .unix(data.dateTime.toString())
  //           .format("DD-MM-YYYY HH:mm:ss");

  //         details.push({ parent, amount, currRate, date });
  //       }
  //       setStakedData(details);
  //     } catch (error) {
  //       console.error("Error fetching staked data:", error);
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (!isUserCountsLoading) {
  //     fetchData();
  //   }
  // }, [sdk, contract, userCounts, address, isUserCountsLoading]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/user/getuserinfo`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            walletAddress: address
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        const userDataFromResponse = responseData.data.userData;
        console.log("userDataFromResponse", userDataFromResponse);
        setStakedData(userDataFromResponse);

        // Set loading to false after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          setLoading(false);
        }, 1000
        );
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [address]);

  const truncateAddress = (address) =>
    address.length > 10 ? `${address.slice(0, 6)}...${address.slice(-4)}` : address;

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = stakedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(stakedData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= pageLimit) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(currentPage - Math.floor(pageLimit / 2), 1);
      let endPage = Math.min(startPage + pageLimit - 1, totalPages);

      if (endPage - startPage < pageLimit - 1) {
        startPage = Math.max(endPage - pageLimit + 1, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="content">
      <div className="container mt-5">
        <div className="parchage_main">
          <div className="page_title">
            <p>DailyIncome overview</p>
          </div>

          <div className="parchage_table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Wallet address</th>
                  <th>Referral</th>
                  <th>Amount</th>
                  <th>Duration</th>
                  <th>Percentage</th>
                  <th>Date & time</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((user, index) => (
                    <tr key={index}>
                      <td>{ index + 1}</td>
                      <td>{truncateAddress(user.walletAddress)}</td>
                      <td>{truncateAddress(user.referralAddress)}</td>
                      <td>{user.amount} USDT</td>
                      <td>{user.duration} Days</td>
                      <td>{user.percentage}</td>
                      <td>{new Date(user.createdAt).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={handlePrevPage}
                className="btn_pri_next"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {pageNumbers.map((number, index) => (
                <button
                  key={index}
                  onClick={() =>
                    typeof number === "number" && handlePageChange(number)
                  }
                  className={currentPage === number ? "active" : ""}
                  disabled={number === "..."}
                >
                  {number}
                </button>
              ))}
              <button
                onClick={handleNextPage}
                className="btn_pri_next"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyRewards;
