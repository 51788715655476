import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { FadeLoader } from "react-spinners";
import { renderToString } from "react-dom/server";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import { BASE_URL } from '../constants';
import greenarrow from "../image/greenarrow.svg";
import demo from "../image/demo.svg";
import copy from "../image/Vector (75).png";
import {
  useTokenBalance,
  useContract,
  useAddress,
  useContractRead,
  useContractWrite,
  checkClientIdOrSecretKey,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';

const Deshbord = () => {
  const { id } = useParams();
  const [USDTAmt, setUSDTAmt] = useState("");
  const [KvcAmt, setKvcAmt] = useState("");
  const [cunAmt, setCunAmt] = useState("");
  const [withdrawAmt, setWithdrawAmt] = useState("");
  const [approveAmt, setApproveAmt] = useState("");
  const [SellTokensloading, setSellTokensLoading] = useState(false);
  const referralLinkRef = useRef(null);
  const [referralCode, setReferralCode] = useState(id);
  const [stakeAmount, setStakeAmount] = useState("10");
  const [isLoading, setIsLoading] = useState(false);
  const [duration2, setDuration] = useState("300");
  const [percentage2, setPercentage] = useState("0.5");
  const { protocol, hostname, port } = window.location;

  useEffect(() => {
    setReferralCode(id);
    console.log("id", id);
  }, [id]);
  //read functions
  const address = useAddress();
  const { contract } = useContract(
    "0xBE2De0D93DaDF21839aCc6d67d9d33d12cb5bb7C"
  );
  const { data: cunWalletBal, isLoading: isCunWalletBalLoading } =
    useTokenBalance(contract, address);
  const { contract: USDTContract } = useContract(
    "0x55d398326f99059fF775485246999027B3197955"
  );
  const { contract: TDFContract } = useContract(
    "0x7d185d79b7D28be5A9a9F8F1dCE6AAb7e5462c83"
  );
  const { data: walletBal, isLoading: walletBalLoading } = useTokenBalance(
    USDTContract,
    address
  );

  const { data: TDFwalletBal, isLoading: tdfwalletBalLoading } = useTokenBalance(
    TDFContract,
    address
  );

  const { data: rewardAmt, isLoading: isRewardAmtLoading } = useContractRead(
    contract,
    "RewardAmount",
    [address]
  );

  const { data: parent, isLoading: isParentLoading } = useContractRead(
    contract,
    "parent",
    [address]
  );
  const { data: availableRewards, isLoading: isAvailableRewardsLoading } =
    useContractRead(contract, "getAvailableRewards", [address]);

 
  const { data: rewardLimit, isLoading: isRewardLimitLoading } =
    useContractRead(contract, "getRewardLimit", [address]);
  const { data: totalWithdrawn, isLoading: istotalWithdrawnLoading } =
    useContractRead(contract, "totalWithdrawn", [address]);
  const { data: rate, isLoading: isTokenPriceLoading } = useContractRead(
    contract,
    "rate",
    []
  );
  const { data: owner, isLoading: isOwnerLoading } = useContractRead(
    contract,
    "Owner",
    []
  );
  const { data: totalInvested, isLoading: istotalInvestedLoading } =
    useContractRead(contract, "totalInvested", [address]);

  const { data: directChild, isLoading: isDirectChildLoading } =
    useContractRead(contract, "showAllDirectChild", [address]);
  const { data: indirectChild, isLoading: isIndirectChildLoading } =
    useContractRead(contract, "showAllInDirectChild", [address]);
  const { data: userLevels, isLoading: isUserLevelsLoading } = useContractRead(
    contract,
    "userLevels",
    [address]
  );
  const { data: sellLimit, isLoading: isSellLimitlsLoading } = useContractRead(
    contract,
    "getSellingLimit",
    [address]
  );
  const { data: soldLimit, isLoading: isSoldLimitlsLoading } = useContractRead(
    contract,
    "totalAmountSold",
    [address]
  );
  const { data: poolLimit, isLoading: ispoolLimitlsLoading } = useContractRead(
    contract,
    "UsdtPool",
    []
  );
  const { data: supply, isLoading: issupplyLoading } = useContractRead(
    contract,
    "totalSupply",
    []
  );

  const { data: LPool, isLoading: isPoolLoading } = useContractRead(
    contract,
    "UsdtPool",
    []
  );

  const { data: totalPurchases, isLoading: checkRewardsLoading } =
    useContractRead(contract, "totalPurchases", [address]);

  


  const { data: totalPurchasesOfRef, isLoading: checkRefLoading } =
  useContractRead(contract, "totalPurchases", [referralCode]);

  
  const { data: referralEarnings, isLoading: checkBonusLoading } =
    useContractRead(contract, "referralEarnings", [address]);


  const { data: idCardNumbers, isLoading: showAllDirectChildLoading } =
    useContractRead(contract, "idCardNumbers", [address]);

  const { data: myInvites, isLoading: showAllInDirectChildLoading } =
    useContractRead(contract, "myInvites", [address]);

  const { data: updateLevelIncome, isLoading: updateLevelIncomeLoading } =
    useContractRead(contract, "updateLevelIncome", [address]);

  const {
    data: viewTeamLeadershipRewards,
    isLoading: viewTeamLeadershipRewardsLoading,
  } = useContractRead(contract, "viewTeamLeadershipRewards", [address]);

  const { data: getParent, isLoading: totalReferralRewardsLoading } =
    useContractRead(contract, "getParent", [address]);

  const { data: totalremovedRewards, isLoading: totalremovedRewardsLoading } =
    useContractRead(contract, "totalRewardWithdraw", [address]);

  const { data: totalInvestedAmount, isLoading: totalInvestedAmountLoading } =
    useContractRead(contract, "totalInvestedAmount", [address]);

  console.log("parent", getParent);
  console.log("Checkkss", parseInt(totalPurchasesOfRef/1000000000000000000));


  //approve tokens
  const { mutateAsync: approve, isLoading: isApproveLoading } =
    useContractWrite(USDTContract, "approve");

  const handleCopyReferralLink = () => {
    if (referralLinkRef.current) {
      referralLinkRef.current.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      // Use react-toastify to display a toaster notification
      toast.success("Referral link copied to clipboard!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /***************************Total rewad code start ***************************/
  const [stackamouttotal, setstackamouttotal] = useState('');
  const [totalUsrWithdrwalRewardAmount, setotalUsrWithdrwalRewardAmount] = useState('');
  const [totalLimit, settotalLimit] = useState('');
  const [totalTeamCount, settotalTeamCount] = useState('');
  const [teamLevel, setteamLevel] = useState('');

  const StackAmountGet = () => {
    try {
      fetch(`${BASE_URL}/api/v1/user/rewardAmountList`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: address
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // console.log("StackAmountGet", data);
          setstackamouttotal(data.data.totalRewardAmount);
          setotalUsrWithdrwalRewardAmount(data.data.totalUsrWithdrwalRewardAmount)
          settotalLimit(data.data.totalLimit)
          settotalTeamCount(data.data.totalTeamcount)
          setteamLevel(data.data.teamLevel);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    } catch (error) {
      console.error('There was an error:', error);
    }
  };

  useEffect(() => {
    StackAmountGet();
  }, [address]);

  const approveTokens = async () => {
    try {
      setIsLoading(true);
      let spender = "0xBE2De0D93DaDF21839aCc6d67d9d33d12cb5bb7C"; //contract address
      let approveAmount = ethers.utils.parseEther(approveAmt);
      const data = await approve({ args: [spender, approveAmount] });
      console.info("contract call successs", data);
      toast.success("Successfully approved USDT!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    } catch (err) {
      toast.error("Approve Failed !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      console.error("contract call failure", err);
    } finally {
      setApproveAmt("");
      setIsLoading(false);
    }
  };

  // buyTokens
  const { mutateAsync: buyTokens, isLoading: isBuyTokensLoading } =
    useContractWrite(contract, "buyTokens");

  const { mutateAsync: assignIdCardNumber, isLoading: isIdLoading } =
    useContractWrite(contract, "assignIdCardNumber");

  let modifiedAmount = stakeAmount.replace("JSR", "");

  const currentTime = new Date();

  let percentage;
  let duration;

  if (modifiedAmount == 50) {
    percentage = 0.5;
    duration = 300;
  } else if (modifiedAmount == 100) {
    percentage = 0.75;
    duration = 300;
  } else if (modifiedAmount >= 500 && modifiedAmount < 1000) {
    percentage = 1;
    duration = 400;
  } else if (modifiedAmount >= 1000 && modifiedAmount < 5000) {
    percentage = 1.25;
    duration = 400;
  } else if (modifiedAmount >= 5000 && modifiedAmount < 10000) {
    percentage = 1.5;
    duration = 400;
  } else if (modifiedAmount >= 10000 && modifiedAmount < 50000) {
    percentage = 1.75;
    duration = 400;
  } else if (modifiedAmount >= 50000) {
    percentage = 2;
    duration = 400;
  } else {
    // Handle other cases if needed
  }


  const AddDataAfterStake = async () => {
    setIsLoading(true);
    console.log("called");
    try {
      const response = await fetch("https://backend.smartprofitx.io/user/time", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          duration: duration.toString(),
          walletId: address,
          amount: Number(modifiedAmount),
          referral_id: referralCode,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
        setIsLoading(false);
      }
      // Handle success
      setIsLoading(false);
      toast.success("You have successfully staked USDT", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("Data sent successfully!");
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("There was a problem sending the data:", error.message);
    }
  };

  // const WithdrawDetails = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch("https://backend.smartprofitx.io/withdrawal/create", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         walletId: address,
  //         amount: withdrawAmt,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     // Handle success
  //     setIsLoading(false);
  //     toast.success("Tokens Has Been Successfully Withdrawn", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   } catch (error) {
  //     // Handle error
  //     setIsLoading(false);
  //     console.error("There was a problem sending the data:", error.message);
  //   }
  // };


  /*************************** stack btn backend api start ***************************/
  const ButtonStake = () => {


    fetch(`${BASE_URL}/api/v1/user/add`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        walletAddress: address,
        amount: stakeAmount,
        percentage: percentage2,
        duration: duration2.toString(),
        limit: '75$',
        referralAddress: referralCode
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        if (data.statusCode === 200) {
          //console.log("data insert"); 

        } else {
          console.log("data not insert")
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  /*************************** stack btn backend api end ***************************/

  const stakeToken = async () => {
    setIsLoading(true);
    try {
      let ref;
      if (getParent === "0x0000000000000000000000000000000000000000") {
        ref = id;
      } else {
        ref = getParent;
      }
      let usdtAmt = ethers.utils.parseEther(modifiedAmount);
      console.log("usdtAmt", usdtAmt);
      console.log("id", id);
      console.log("parent", getParent);

      const data = await buyTokens({ args: [usdtAmt, ref] });
      console.info("contract call successs", data);
      toast.success("Successfully purchased CWN tokens", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error("Something Went Wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("contract call failure", err);
    } finally {
      setUSDTAmt("");
      setIsLoading(false);

    }
  };

  // const stakeToken = async () => {
  //   setIsLoading(true);

  //   try {
  //     let ref;
  //         if (getParent === "0x0000000000000000000000000000000000000000") {
  //           ref = referralCode;
  //         } else {
  //           ref = referralCode;
  //         }
  //     // Check if totalPurchases is valid
  //     if (parseInt(totalPurchasesOfRef / 1000000000000000000) < 1) {
  //       toast.error("Invalid referrer", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setIsLoading(false);
  //       return; // Exit the function if referrer is invalid
  //     } else if (parseInt(totalPurchasesOfRef / 1000000000000000000) >= 1) {
  //       // Proceed with token purchase if referrer is valid
  //       let usdtAmt = ethers.utils.parseEther(modifiedAmount);

  //       console.log("usdtAmt", usdtAmt);
  //       console.log("id", id);
  //       console.log("parent", getParent);

  //       const data = await buyTokens({ args: [usdtAmt, ref] });
  //       console.info("contract call success", data);
  //       toast.success("Successfully purchased CWN tokens", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (err) {
  //     toast.error("Something Went Wrong", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     console.error("contract call failure", err);
  //   } finally {
  //     setUSDTAmt("");
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    if (
      !isTokenPriceLoading 
    ) {
     
    }
    if (!isRewardAmtLoading) {
      console.log(rewardAmt.toString());
    }
    if (!isParentLoading) {
      console.log(parent);
    }
    if (
      !isAvailableRewardsLoading &&
      !isRewardLimitLoading &&
      !isOwnerLoading
    ) {
      console.log("rew limit : ", rewardLimit.toString());
      console.log("availableRewards : ", availableRewards.toString());
      console.log("owner", owner);
    }
  }, []);

  const AddID = async () => {
    setIsLoading(true);
    try {
      let ref;
      if (parent === "0x0000000000000000000000000000000000000000") {
        ref = referralCode;
      } else {
        ref = referralCode;
      }
      let usdtAmt = ethers.utils.parseEther(modifiedAmount);

      console.log("usdtAmt", usdtAmt);
      console.log("id", referralCode);

      const data = await assignIdCardNumber({ args: [KvcAmt] });
      console.info("contract call successs", data);
      toast.success("ID Assigned successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error("Something Went Wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("contract call failure", err);
    } finally {
      setKvcAmt("");
      setIsLoading(false);
    }
  };

  //sell Token
  const { mutateAsync: sellTokens, isLoading: isSellTokenLoading } =
    useContractWrite(contract, "sellTokens");

  const sellToken = async () => {
    try {
      setSellTokensLoading(true);
      let amount = ethers.utils.parseEther(cunAmt);
      const data = await sellTokens({ args: [amount] });
      console.info("contract call successs", data);
      toast.success("Tokens sold successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error("Selling amount exceeds limit", {
        position: toast.POSITION.TOP_CENTER,
      });

      console.error("contract call failure", err);
    } finally {
      setCunAmt("");
      setSellTokensLoading(false);
    }
  };
  //withdraw Tokens

  const withthrowalamountBtn = () => {
    if (totalLimit >= withdrawAmt) {
      try {
        fetch(`${BASE_URL}/api/v1/user/withdrawalAmount`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            walletAddress: address,
            amount: withdrawAmt
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log("widtrwaldata", data.data.messages);
            toast(data.data.messages);

          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      } catch (error) {
        console.error('There was an error:', error);
      }
    } else {
      console.log("Not enough in your withdrawal limit");
      toast.error({
        message: "Not enough in your withdrawal limit",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const { mutateAsync: withdraw, isLoading: isWithdrawTokensLoading } =
    useContractWrite(contract, "withdraw");

  const withdrawToken = async () => {
    try {
      setIsLoading(true);
      let amount = ethers.utils.parseEther(withdrawAmt);
      const data = await withdraw({ args: [withdrawAmt] });
      console.info("contract call successs", data);
      withthrowalamountBtn();
      toast.success("Successfully withdrawn USDT", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error("Tokens Withdraw Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("contract call failure", err);
    } finally {

      setWithdrawAmt("");
      setIsLoading(false);

    }
  };

  useEffect(() => {
    if (
      !walletBalLoading &&
      !isCunWalletBalLoading &&
      !isTokenPriceLoading &&
      !istotalWithdrawnLoading
    ) {
      console.log("contract : ", contract);
      console.log(address);
      console.log("usdtBal", walletBal);
      console.log("cun bal : ", cunWalletBal);
      
      console.log("totalWithdrawn : ", totalWithdrawn?.toString());
    }
    if (!isRewardAmtLoading) {
      console.log(rewardAmt?.toString());
    }
    if (!isParentLoading) {
      console.log(parent);
    }
    if (
      !isAvailableRewardsLoading &&
      !isRewardLimitLoading &&
      !isOwnerLoading
    ) {
      console.log("rew limit : ", rewardLimit?.toString());
      console.log("availableRewards : ", availableRewards?.toString());
      console.log("owner", owner);
    }
  }, []);

  console.log("parent", getParent);
  console.log("token price : ", parseInt(rate));
  
  let currentrate = (parseInt(rate));
  console.log("currentrate", currentrate);
  console.log("total purchase : ", parseInt(totalPurchases)/1000000000000000000);
  let purchases =  (parseInt(totalPurchases)/1000000000000000000);

  useEffect(() => {
    let ref
    if (getParent === "0x0000000000000000000000000000000000000000") {
      ref = id;
    } else {
      ref = getParent;
    }
    setReferralCode(ref);
  }, [id, getParent]);

  

  const handleStakeAmount = (e) => {
    setStakeAmount(e.target.value);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const handleSelectChange = (e) => {
    const value = parseInt(e.target.value, 10);

    setStakeAmount(e.target.value);
    switch (value) {
      case 25:
        setDuration(300);
        setPercentage(0.5);
        break;
      case 100:
        setDuration(300);
        setPercentage(0.75);
        break;
      case 500:
        setDuration(400);
        setPercentage(1);
        break;
      case 1000:
        setDuration(400);
        setPercentage(1.25);
        break;
      case 5000:
        setDuration(400);
        setPercentage(1.50);
        break;
      case 10000:
        setDuration(400);
        setPercentage(1.75);
        break;
      case 50000:
        setDuration(400);
        setPercentage(2);
        break;
      default:
        setDuration(300);
        setPercentage(0.5);
    }
  };



  return (
    <div className="">
      <div className="content">
        <ToastContainer />
        {isLoading && (
          <div className="loader_new">
            <FadeLoader color="#36d7b7" />
          </div>
        )}
        <div className="cunholder cntainer-fluid">
          <div className="mt-3">
            <p>Dashboard overview</p>
          </div>
        </div>

        <div className="section2">
          <div className="cntainer-fluid my-3">
            <div className="row">
              <div className="col-lg-5 col-md-6 mb-3">
                <div className="widthdrow approve-token-new p-3" style={{ borderRadius: 14 }}>
                  <h4 className="text-red-400">
                    Hello, <span style={{ color: "#784eba" }}>CWN</span> holder !!
                  </h4>
                  <p>NOTE : To become a referrer you need to purchase tokens more than 10 USDT</p>
                  <div className="d-flex py-3">
                    <div className="Available  py-2" style={{
                      width: "45%", justifyContent: "center", alignItems: "center", border: "1px solid #494b51", background: '#22262f', borderRadius: 14
                    }}>
                      <input
                        type="number"
                        placeholder="Enter USDT"
                        className="px-5"
                        style={{
                          borderTop: "0px solid #000",
                          borderBottom: "0px solid #000",
                          borderLeft: "0px solid #000",
                          borderRight: "0px solid #000", margin: 0,
                        }}
                        onChange={(el) => {
                          setStakeAmount(el.target.value)
                        }}
                      />
                    </div>
                    <div className="Available mt-2" style={{ width: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={demo} className="img-fluid w-50" />
                    </div>
                    <div className="Available  py-2" style={{
                      width: "45%", justifyContent: "center", alignItems: "center", border: "1px solid #494b51", background: '#22262f', borderRadius: 14
                    }}>
                      <input
                        value={stakeAmount * currentrate}
                        type="number"
                        placeholder="You will get "
                        className="px-5"
                        style={{
                          borderTop: "0px solid #000",
                          borderBottom: "0px solid #000",
                          borderLeft: "0px solid #000",
                          borderRight: "0px solid #000", margin: 0,
                        }}
                      />
                    </div>
                  </div>
                  <div className="Available d-flex py-2" style={{
                    justifyContent: "center", alignItems: "center", border: "1px solid #494b51", background: '#22262f', borderRadius: 14
                  }}>
                   
                    <input
                      value={id !== null ? referralCode : id}
                      type="text"
                      disabled
                      className="px-5"
                      placeholder="Enter Referral Address"
                      style={{
                        borderTop: "0px solid #000",
                        borderBottom: "0px solid #000",
                        borderLeft: "0px solid #000",
                        borderRight: "0px solid #000", margin: 0,
                        borderRadius: 14,
                        letterSpacing: "1px" 
                      }}
                      onChange={(el) => {
                        setReferralCode(el.target.value)
                      }}
                    />
                  </div>
                  <div className="stake_button_div">
                    <button
                      onClick={stakeToken}
                      // disabled={stakeAmount < 24}
                      className="w-100 my-4 mx-1 py-3 "
                      style={{
                        background: '#22262f', justifyContent: "center", border: "none", borderRadius: 14,
                        border: "2px solid #533680"
                      }}
                    >
                      Click to Buy
                      <span className="greenarrow" style={{ position: "relative", left: 65 }}>
                        <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-3">
                <div className="widthdrow approve-token-new p-3 h-100" style={{ borderRadius: 14 }}>
                  <h4 className="text-red-400">
                    Approve USDT
                  </h4>
                  <p>NOTE : Approve amount of USDT You want to purchase</p>
                  <div className="d-flex">
                    <div className="Available  py-2" style={{
                      width: "100%", justifyContent: "center", alignItems: "center", border: "1px solid #494b51", background: '#22262f', borderRadius: 14
                    }}>
                      <input
                        type="number"
                        onChange={(el) => {
                          setApproveAmt(el.target.value)
                        }}
                        placeholder="Enter USDT Amount"
                        className="px-5"
                        style={{
                          borderTop: "0px solid #000",
                          borderBottom: "0px solid #000",
                          borderLeft: "0px solid #000",
                          borderRight: "0px solid #000", margin: 0,
                        }}
                      />
                    </div>
                  </div>
                  <div className="stake_button_div">
                    <button
                      onClick={approveTokens}
                      // disabled={stakeAmount < 24}
                      className="w-100 my-4 mx-1 py-3 "
                      style={{ background: '#22262f', justifyContent: "center", border: "none", borderRadius: 14, border: "2px solid #533680" }}
                    >
                      Approve USDT
                      <span className="greenarrow" style={{ position: "relative", left: 65 }}>
                        <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="widthdrow approve-token-new my-2">
                  <h3 className="approve-heading flex justify-content-center  p-3  align-items-center">
                    Referral Information
                  </h3>
                  <div className="d-flex justify-content-center align-content-center">
                    <p style={{ color: "#fff", fontWeight: 400 }}> https://presale.copywing.io/.{address?.slice(30, -1)}</p>
                    <img src={copy} alt="puricon" className="mx-2 mt-1" width={15} height={18} onClick={() => {
                      if ((parseInt(totalPurchases?._hex, 16) / 1000000000000000000).toFixed(3) >= 1) {
                        var copyText = "https://presale.copywing.io/" + address
                        //console.log("copyText", copyText);
                        navigator.clipboard.writeText(copyText).then(() => {
                          toast.success("Referral link copied", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        }).catch(err => {
                          console.error('Failed to copy: ', err);
                        });
                      } else {
                        toast.error("Referral link is not active yet", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                    }} />

                  </div>
                </div>
                <div className="widthdrow approve-token-new my-4">
                  <h3 className="approve-heading flex justify-content-center p-3  align-items-center">
                    Add KYC here
                  </h3>
                  <div className="d-flex justify-content-center align-content-center p-2">
                    <div className="Available m-2 py-2" style={{
                      width: "75%", justifyContent: "left", alignItems: "left", border: "1px solid #494b51", background: '#22262f', borderRadius: 14
                    }}>
                      <input
                        disabled={idCardNumbers?.length > 3 ? true : false}
                        value={KvcAmt}
                        type="text"
                        placeholder="Enter name as on passport"
                        style={{
                          borderTop: "0px solid #000",
                          borderBottom: "0px solid #000",
                          borderLeft: "0px solid #000",
                          borderRight: "0px solid #000", margin: 0,
                        }}
                        onChange={(el) => {
                          setKvcAmt(el.target.value)
                        }}
                      />
                    </div>
                    <div className="Available d-flex m-2 py-2" style={{
                      width: "25%", justifyContent: "center", alignItems: "center", border: "1px solid #533680", background: '#22262f', borderRadius: 14
                    }}>
                      <button
                        onClick={AddID}
                        disabled={idCardNumbers?.length > 3 ? true : false}
                        className="w-100 my-1 mx-1 py-1 "
                        style={{ background: '#22262f', justifyContent: "center", border: "none", borderRadius: 14 }}
                      >Submit
                      </button>
                    </div>
                  </div>
                </div>

                <div className="Available d-flex py-2" style={{
                  justifyContent: "center", alignItems: "center", border: "1px solid #494b51", background: '#22262f', borderRadius: 14
                }}>
                  <input
                    value={"ID"}
                    type="text"
                    className="w-25 pl-2 px-0 px-md-4 text-center"
                    style={{
                      borderTop: "0px solid #000",
                      fontSize: "12px",
                      borderBottom: "0px solid #000",
                      borderLeft: "0px solid #000",
                      borderRight: "0px solid #000", margin: 0,
                    }} onChange={(el) => {
                      setReferralCode(el.target.value)
                    }}
                  />
                  <input
                    value={idCardNumbers}
                    type="text"
                    className="px-5"
                    placeholder="Your ID will appear here"
                    style={{
                      borderTop: "0px solid #000",
                      borderBottom: "0px solid #000",
                      borderLeft: "0px solid #000",
                      borderRight: "0px solid #000", margin: 0,
                      borderRadius: 14
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section3">
          <div className="container-fluid p-0">
            <div className="section3_chaid grid-container">
              <div className="balance_info">
                <p>
                  Available USDT
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <h4 className="text-red-500">
                  {walletBalLoading
                    ? "0.00"
                    : parseFloat((walletBal?.displayValue)).toFixed(2)}{" "}
                  USDT{" "}
                </h4>
              </div>
              <div className="balance_info">
                <p>
                  Total Purchase
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <h4>
                {checkRewardsLoading || totalPurchases == null || totalPurchases === undefined
  ? "0.00"
  : parseFloat(totalPurchases / 1000000000000000000).toFixed(2)} USDT

                 
                </h4>
              </div>
              <div className="balance_info">
                <p>
                  My Referrals
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <h4>
                  {!myInvites ? 0 : myInvites?.length}
                </h4>
              </div>
              <div className="balance_info">
                <p>
                  Referral Earnings{" "}
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <div className="withbalench">
                  <h4>
                  {(parseInt(referralEarnings?._hex, 16) / 1000000000000000000
                  ).toFixed(3) !== null
                    ? "0.00"
                    :
                    ((parseInt(referralEarnings?._hex, 16) / 1000000000000000000
                    ).toFixed(3))}{" "}
                  USDT{""}
                  </h4>

                </div>
              </div>
            </div>
            <div className="section3_chaid grid-container py-4">
              <div className="balance_info">
                <p>
                  My CWN Tokens
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <h4 className="text-red-500">
                  {tdfwalletBalLoading
                    ? "0.00"
                    : parseFloat((TDFwalletBal?.displayValue)).toFixed(2)}{" "}
                  CWN{" "}
                </h4>
              </div>
              <div className="balance_info">
                <p>
                  My Team
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <h4>
                  {!myInvites ? 0 : myInvites?.length}
                </h4>
              </div>
              {/* <div className="balance_info">
                <p>
                  Direct / Total{" "}
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <div className="withbalench">
                  <h4>{idCardNumbers?.length}</h4> <span>/</span>
                  <h4>{idCardNumbers?.length}</h4>
                </div>
              </div> */}
              {/* <div className="balance_info">
                <p>
                  Direct / Total{" "}
                  <span className="greenarrow">
                    <img src={greenarrow} alt="puricon" width={20} className="mx-3" />
                  </span>
                </p>
                <div className="withbalench">
                  <h4>{idCardNumbers?.length}</h4> <span>/</span>
                  <h4>{idCardNumbers?.length}</h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer">
          <p className="text-light" style={{ fontWeight: 400 }}>All Rights Reserved.Copywing.io 2024-25</p>
        </div>
      </div>
    </div >
  );
};

export default Deshbord;
